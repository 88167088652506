<template>
  <div class="bg-indigo pt-[16vw] md:pt-[5vw] font-work">
    <!-- Hero -->
    <section class="relative text-center bg-sky pt-[10vw] md:pt-[4vw] md:pb-[1.5vw] text-white tracking-[-0.01em]">
      <div class="px-[6vw] md:px-0 md:max-w-[64vw] flex flex-col items-center mx-auto">
        <div v-if="coming"
             class="absolute top-1/4 right-[8vw] md:left-2/3 w-[20vw] h-[20vw] md:w-[6vw] md:h-[6vw] flex items-center text-center md:px-[1vw] z-10"
        >
          <SvgShapeGear class="absolute inset-0 w-full h-full animate-scale text-lemon" />
          <span class="relative text-black font-oswald uppercase text-[4vw] md:text-[1.2vw] font-medium leading-[1.1] tracking-[-0.01em]">Coming soon</span>
        </div>
        <GameIcon v-if="content.game.icon"
                  class="h-[24vw] w-[24vw] md:h-[6vw] md:w-[6vw] md:mb-[2vw] mb-[6vw]"
                  :src="content.game.icon"
        />
        <h1 class="font-medium uppercase font-oswald mb-[3vw] md:mb-[1.25vw] text-[5vw] leading-[6vw] md:text-[1.875vw] md:leading-[2.25vw]">
          {{ content.game.title }}
        </h1>
        <h2 class="leading-none text-[12vw] md:text-[5vw] font-extrabold">
          {{ content.hero.title }}
        </h2>
      </div>
    </section>

    <!-- Video -->
    <section v-if="content.hero.videoPlaylistId"
             class="md:px-[5.625vw] bg-sky pt-[12vw] md:pt-[2.5vw] md:pb-[5vw] -mt-px"
    >
      <!-- Wrapper for @tailwindcss/aspect-ratio padding trick -->
      <div class="md:aspect-w-16 md:aspect-h-9">
        <div @click="openModal()"
             class="cursor-pointer md:max-h-[calc(100vh-5vw)] overflow-hidden md:shadow-m mx-auto md:rounded-[1.25vw] relative group"
             :style="{ clipPath: 'content-box' }"
        >
          <div :id="`video-player-background-${content.hero.videoPlaylistId}`"
               class="object-cover w-full h-full"
          />
          <div class="absolute inset-0 flex items-center justify-center duration-300 scale-75 md:scale-110 md:opacity-0 md:group-hover:opacity-100">
            <ButtonPlay />
          </div>
        </div>
      </div>
    </section>

    <!-- Screenshots -->
    <section class="text-center text-white">
      <div class="-mt-px bg-sky">
        <div class="flex flex-col px-[6vw] pt-[14vw] md:pt-[4.5vw] justify-center md:max-w-[62vw] mx-auto md:px-[2.5vw] md:pb-[1.5vw]">
          <h3 class="md:mb-[2vw] font-extrabold text-[10vw] leading-none md:text-[4vw] tracking-[-0.01em] mb-[6vw]">
            {{ content.screenshots.title }}
          </h3>
          <p class="text-[5vw] leading-[7vw] md:text-[1.25vw] md:leading-[1.75vw] md:px-[6vw]"
             v-html="content.screenshots.subtitle"
          />
        </div>

        <BlogScreenshotSlider class="mt-[10vw] md:mt-[5vw] pb-[10vw] md:pb-[5vw]"
                              :slides="content.screenshots.items"
                              caption=""
        />
      </div>

      <div class="h-[30vw] md:h-[22vw] relative overflow-hidden -mt-px">
        <SvgShapeGearOval class="absolute h-[91vw] w-[187.5vw] md:h-[56.25vw] md:w-[116vw] text-sky md:bottom-[2.75vw] bottom-[8vw] md:left-[-8vw] left-[-43.75vw]" />
      </div>
    </section>

    
    <Container v-if="content.promo.title"
               class="mt-[6vw] md:mt-[7vw] md:mb-[1.25vw]"
    >
      <CardPromoApp v-bind="content.promo"
                    :stores="content.game.stores"
      />
    </Container>

    <!-- play a game -->
    <AnimateFadeIn v-if="content.play.cards && content.play.cards.length > 0">
      <Slider class="md:pb-[7.5vw] pt-[14vw] md:pt-[7vw]"
              :per-view-md="5"
              :title="content.play.title"
      >
        <Card v-for="(card, index) in content.play.cards"
              class="keen-slider__slide"
              :key="index"
              v-bind="card"
        />
      </Slider>
    </AnimateFadeIn>

    <!-- watch a game -->
    <AnimateFadeIn v-if="content.watch.cards && content.watch.cards.length > 0">
      <Slider class="md:pb-[10vw] md:pt-[4.5vw] pt-[14vw] pb-[40vw]"
              :per-view-md="3"
              :per-view="1.3"
              :title="content.watch.title"
      >
        <CardVideo v-for="(card, index) in content.watch.cards"
                   class="keen-slider__slide"
                   :key="index"
                   v-bind="card"
        />
      </Slider>
    </AnimateFadeIn>
  </div> 
 
  <SectionGameDownload v-if="gameHasInstallLinks()"
                       :stores="content.game.stores"
  />
</template>

<script setup lang="ts">
import { useState } from '#imports';
import { useEmbedJwplayer } from '~/composables/use-embed-jwplayer';
import { useVideoPlaylistUrl } from '~/composables/use-video-playlist-url';

type TemplateGameProps = {
    content: any,
    coming: boolean
}

const props = defineProps<TemplateGameProps>();
const isOpen = useState('video-popup', () => false);
const playlist = useState('video-playlist', () => '');

const { playlistUrl } = useVideoPlaylistUrl(props.content.hero.videoPlaylistId);

function embedJwplayer() {
  if (playlistUrl.value !== '') {
  // @ts-expect-error (jwplayer does not provide types)
    window.jwplayer(`video-player-background-${props.content.hero.videoPlaylistId}`).setup({
      'controls': false,
      'repeat': true,
      'nextUpDisplay': false,
      'displaydescription': false,
      'stretching': 'fill',
      'mute': true,
      'displaytitle': false,
      'playlist': playlistUrl.value
    });
  }
}

useEmbedJwplayer(embedJwplayer);

function openModal() {
  isOpen.value = true;
  playlist.value = props.content.hero.videoPlaylistId;
}

function gameHasInstallLinks() {
  const obj = props.content.game.stores;
  for (let key in obj) {
    // eslint-disable-next-line
    if (obj.hasOwnProperty(key) && obj[key] !== '') {
      return true;
    }
  }
  return false;
}
</script>
